<template>
  <div class="m-form-faq-question" :class="formClasses">
    <form class="m-form-faq-question__form" @submit.prevent="submitHandler" autocomplete="off">
      <div
        v-if="stateModel === EFormStates.SUCCESS"
        class="m-form-faq-question__form-success m-form__success"
      >
        <div class="m-form__status-title">{{ props.successTitle }}</div>

        <div class="m-form__status-subtitle" v-html="props.successSubtitle"></div>

        <div class="m-form__status-button">
          <Button modifier="tertiary" theme="dark" @click="closeForm('success')">Закрыть</Button>
        </div>
      </div>

      <div
        v-else-if="stateModel === EFormStates.ERROR"
        class="m-form-faq-question__form-error m-form__error"
      >
        <div class="m-form__status-title">{{ props.errorTitle }}</div>

        <div class="m-form__status-subtitle" v-html="props.errorSubtitle"></div>

        <div class="m-form__status-button">
          <Button modifier="tertiary" theme="dark" @click="closeForm('error')">Вернуться</Button>
        </div>
      </div>

      <div class="m-form-faq-question__form-default m-form__default">
        <Select
          v-model="formData.type"
          :options="categories"
          option-label="title"
          option-value="uuid"
          label="Категория вопроса*"
          placeholder="Категория вопроса"
          theme="dark"
          :valid="
            !v$.type.$invalid &&
            !errorsAggregator(v$.type.$errors, formBackendErrors).filter('type').length
          "
          :invalid="!!errorsAggregator(v$.type.$errors, formBackendErrors).filter('type').length"
          :errors="errorsAggregator(v$.type.$errors, formBackendErrors).filter('type')"
          @input="v$.type.$touch()"
          @change="v$.type.$touch()"
        >
          <template #toggleIcon>
            <i class="icon-main-down"></i>
          </template>

          <template #cleanIcon>
            <i class="icon-main-close"></i>
          </template>

          <template #errors="{ errors }">
            <span v-for="(error, i) in errors" :key="`error-${i}`">
              {{ error }}
            </span>
          </template>
        </Select>

        <InputText
          v-model="formData.name"
          label="Имя*"
          theme="dark"
          name="Имя"
          placeholder="Введите имя"
          :valid="
            !v$.name.$invalid &&
            !errorsAggregator(v$.name.$errors, formBackendErrors).filter('name').length
          "
          :invalid="!!errorsAggregator(v$.name.$errors, formBackendErrors).filter('name').length"
          :errors="errorsAggregator(v$.name.$errors, formBackendErrors).filter('name')"
          @input="v$.name.$touch()"
          @change="v$.name.$touch()"
        >
          <template #errors="{ errors }">
            <span v-for="(error, i) in errors" :key="`error-${i}`">
              {{ error }}
            </span>
          </template>
        </InputText>

        <InputText
          v-model="formData.email"
          label="Электронная почта*"
          theme="dark"
          type="email"
          placeholder="Введите электронную почту"
          name="Электронная почта"
          :valid="
            !v$.email.$invalid &&
            !errorsAggregator(v$.email.$errors, formBackendErrors).filter('email').length
          "
          :invalid="!!errorsAggregator(v$.email.$errors, formBackendErrors).filter('email').length"
          :errors="errorsAggregator(v$.email.$errors, formBackendErrors).filter('email')"
          @input="v$.email.$touch()"
          @change="v$.email.$touch()"
        >
          <template #errors="{ errors }">
            <span v-for="(error, i) in errors" :key="`error-${i}`">
              {{ error }}
            </span>
          </template>
        </InputText>

        <InputText
          v-model="formData.city"
          label="Город*"
          theme="dark"
          placeholder="Введите город"
          name="Город"
          :valid="
            !v$.city.$invalid &&
            !errorsAggregator(v$.city.$errors, formBackendErrors).filter('city').length
          "
          :invalid="!!errorsAggregator(v$.city.$errors, formBackendErrors).filter('city').length"
          :errors="errorsAggregator(v$.city.$errors, formBackendErrors).filter('city')"
          @input="v$.city.$touch()"
          @change="v$.city.$touch()"
        >
          <template #errors="{ errors }">
            <span v-for="(error, i) in errors" :key="`error-${i}`">
              {{ error }}
            </span>
          </template>
        </InputText>

        <Textarea
          v-model="formData.question"
          theme="dark"
          label="Вопрос"
          placeholder="Введите текст вопроса"
          :rows="1"
          name="Вопрос"
          modifier="multilines"
          v-textarea-auto-height
        >
        </Textarea>

        <div v-if="getGenericCaptchaState" class="m-form-captcha m-form-captcha--dark">
          <div class="m-form-captcha__header">Введите код с картинки</div>
          <div class="m-form-captcha__box">
            <img
              v-if="captchaImage"
              :src="captchaImage"
              alt="Введите код с картинки"
              title="Введите код с картинки"
              width="120px"
              height="50px"
            />

            <Button
              title="Обновить код капчи"
              aria-label="Обновить код капчи"
              @click="fetchCaptcha"
            >
              <i class="icon-main-reload"></i>
            </Button>
          </div>

          <InputText
            v-model="formData.captcha"
            label="Код*"
            theme="dark"
            placeholder="Введите код"
            name="captcha"
            :invalid="!!errorsAggregator(null, formBackendErrors).filter('captcha').length"
            :errors="errorsAggregator(null, formBackendErrors).filter('captcha')"
          >
            <template #errors="{ errors }">
              <span v-for="(error, i) in errors" :key="`error-${i}`">
                {{ error }}
              </span>
            </template>
          </InputText>
        </div>

        <div class="m-form__form-policy">
          <slot name="policyText">
            Нажимая на кнопку «Отправить», вы соглашаетесь с
            <a href="/docs/privacy_policy.pdf" target="_blank" class="link-dark"
              >политикой обработки персональных данных</a
            >
          </slot>
        </div>

        <Button
          tag="button"
          type="submit"
          css-class="button"
          modifier="primary"
          :loading="stateModel === EFormStates.LOADING || stateModel != EFormStates.DEFAULT"
        >
          <template #loading>
            <Loader></Loader>
          </template>
          <slot name="buttonText">Отправить</slot>
        </Button>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
// vue
import { computed, ref } from 'vue';

// Components
import Select from '@ice-products-ui/vue-library/Select';
import InputText from '@ice-products-ui/vue-library/InputText';
import Textarea from '@ice-products-ui/vue-library/Textarea';
import Button from '@ice-products-ui/vue-library/Button';
import Loader from '@ice-products-ui/vue-library/Loader';

// Directives
import vTextareaAutoHeight from '@ice-products-ui/vue-library/TextareaAutoHeight';

// Types
import type { IFormProps, IFormEmits } from '../form.types';
import type { IFormFAQQuestionModel } from '~/components/M/Forms/MFormFAQQuestion/MFormFAQQuestion.model';
import type { TFaqCategory } from '~/typings/types/faq.types';

// Vuelidate
import { useVuelidate } from '@vuelidate/core';
import validations from '../form.validation.messages';

// Models
import { EFormStates, EFormStateMessages } from '~/components/M/Forms/form.types';
import { FormFAQQuestionModel } from '~/components/M/Forms/MFormFAQQuestion/MFormFAQQuestion.model';
import { EFormNames } from '~/composables/molecules/useForms';

// Composables
import useApiBase from '~/composables/api/base/useApiBase';
import useApiCaptcha from '~/composables/api/captcha/useApiCaptcha';
import { useIceGenericRequest } from '#imports';

// Utils
import errorsAggregator, { type IErrorsBackendInterface } from '~/utils/arrays/errorsAggregator';
import QueryBuilder from '~/utils/queries/query-builder';

const emit = defineEmits<IFormEmits<IFormFAQQuestionModel>>();
const props = withDefaults(defineProps<IFormProps>(), {
  loading: false,
  successTitle: EFormStateMessages.SUCCESS_TITLE_DEFAULT,
  successSubtitle: EFormStateMessages.SUCCESS_SUBTITLE_DEFAULT,
  errorTitle: EFormStateMessages.ERROR_TITLE_DEFAULT,
  errorSubtitle: EFormStateMessages.ERROR_SUBTITLE_DEFAULT,
});

const { baseApiUrl } = useApiBase();
const { getGenericCaptchaState, captchaImage, captchaKey, fetchCaptcha } = await useApiCaptcha(
  EFormNames.FORM_FAQ_QUESTION,
);
const { getGenericContextsData } = useIceGenericRequest();

const stateModel = ref(EFormStates.DEFAULT);
const formData = ref(new FormFAQQuestionModel({}, getGenericCaptchaState.value));
const formBackendErrors = ref<IErrorsBackendInterface | null>(null);
const categories = ref<Array<TFaqCategory>>([]);

onMounted(async () => {
  categories.value = await $fetch<Array<TFaqCategory>>('faq/categories', {
    baseURL: baseApiUrl,
    method: 'GET',
  });
});

/**
 * @description Form classes
 */
const formClasses = computed(() => {
  return {
    'm-form--default': stateModel.value === EFormStates.DEFAULT,
    'm-form--loading': stateModel.value === EFormStates.LOADING,
    'm-form--success': stateModel.value === EFormStates.SUCCESS,
    'm-form--error': stateModel.value === EFormStates.ERROR,
  };
});

/**
 * @description Close form handler
 * @param {string} status
 */
const closeForm = (status: string) => {
  stateModel.value = EFormStates.DEFAULT;
  emit('update:state', EFormStates.DEFAULT);

  if (status === EFormStates.SUCCESS) {
    formData.value.reset();
    v$.value.$reset();
  }
};

/**
 * @description Validation rules
 * @returns {Object}
 */
const rules = computed(() => {
  return {
    type: { required: validations.required('Выберите из списка') },
    name: { required: validations.required() },
    email: { required: validations.required(), email: validations.email },
    city: { required: validations.required() },
  };
});

const v$ = useVuelidate(rules, formData);

/**
 * @description Form submit handler
 */
const submitHandler = async () => {
  v$.value.$touch();

  if (v$.value.$invalid) return;

  stateModel.value = EFormStates.LOADING;

  if (getGenericCaptchaState.value) {
    formData.value.captcha_key = captchaKey.value;
  }

  const queryStr = new QueryBuilder({
    contexts: getGenericContextsData.value,
  })
    .clearQuery()
    .buildQueryString();

  await $fetch(`forms/faq_question?${queryStr}`, {
    baseURL: baseApiUrl,
    method: 'POST',
    body: formData.value,
    onResponseError: (context) => {
      if (context.response.status === 422) {
        formBackendErrors.value = context.response._data;
        stateModel.value = EFormStates.DEFAULT;
        fetchCaptcha();
        return;
      }

      stateModel.value = EFormStates.ERROR;
      emit('update:state', EFormStates.ERROR);
    },
  });

  stateModel.value = EFormStates.SUCCESS;
  emit('update:state', EFormStates.SUCCESS);

  await fetchCaptcha();
};
</script>
